@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --font-family: "Roboto", Arial;

  --color-text: #404040;
  --color-bg: #ffffff;
  --color-primary: #f4f4f4;
  --color-second: #ececec;
  --color-red: #d93128;
}
