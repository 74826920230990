.Navbar {
  height: 5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.navbar__links {
  padding: 0rem 4rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.35rem;
  font-family: var(--font-family);
  color: var(--color-text);
  flex-wrap: wrap;
}

.navbar__links > a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
  .navbar__links {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .navbar__links > a {
    font-size: 0.9rem;
  }

  .navbar__links {
    justify-content: flex-start;
    gap: 1rem;
    padding: 0 1rem;
  }
}
