.Aboutus {
  padding-bottom: 4rem;
}

.aboutus__content {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.aboutus__content .content-1 {
  width: 100%;
  position: relative;
  background: url("../../img/footer_img.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 42.5rem;
  position: relative;
}

.aboutus__content .content-1 .content-1_text {
  position: absolute;
  top: 1.5rem;
  left: 4.5rem;
  color: white;
  font-family: var(--font-family);
}

.aboutus__content .content-1 .content-1_text h3 {
  font-size: 7rem;
}

.aboutus__content .content-1 .content-1_text p {
  padding-top: 0.5rem;
  font-size: 2.2rem;
  font-weight: 500;
}

.aboutus__content .content-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6.5rem;
}

.content-2_bg {
  width: 75%;
  margin-left: 5rem;
}

.content-2_bg img {
  width: 100%;
}

.content-2_text {
  display: flex;
  margin-right: 2rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  color: var(--color-text);
}

.content-2_text h4 {
  font-size: 5rem;
  font-weight: lighter;
}

.content-2_text p {
  font-size: 1.25rem;
  font-weight: lighter;
  padding: 1rem 9rem 0;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .aboutus__content .content-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
  }

  .aboutus__content .content-1 .content-1_text {
    left: 2rem;
    top: 1rem;
  }
  .aboutus__content .content-1 .content-1_text h3 {
    font-size: 5.5rem;
  }

  .aboutus__content .content-1 .content-1_text p {
    padding-top: 0.5rem;
    font-size: 1.8rem;
    font-weight: 500;
  }

  .content-2_text {
    margin-right: 1rem;
  }

  .content-2_text h4 {
    font-size: 3.5rem;
  }

  .content-2_text p {
    font-size: 1.05rem;
    padding: 1rem 1rem 0 2rem;
  }

  .content-2_bg {
    width: 90%;
    margin-left: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .aboutus__content {
    gap: 2rem;
  }

  .aboutus__content .content-1 .content-1_text {
    left: 0.75rem;
    top: 1rem;
  }

  .aboutus__content .content-1 .content-1_text h3 {
    font-size: 2.5rem;
  }

  .aboutus__content .content-1 .content-1_text p {
    font-size: 1.2rem;
  }

  .aboutus__content .content-2 {
    flex-direction: column;
    gap: 1.5rem;
  }

  .content-2 .content-2_bg {
    margin: 0;
  }

  .content-2 .content-2_text {
    margin: 0;
    padding: 0;
  }

  .content-2 .content-2_text h4 {
    font-size: 2.2rem;
  }

  .content-2 .content-2_text p {
    font-size: 0.85rem;
  }
}
