.Footer {
  max-height: 450px;
  background-color: var(--color-second);
  padding: 0.2rem 3.5rem 0;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2.5rem;
  font-family: var(--font-family);
  color: var(--color-text);
}

.footer__content .footer__logo_description p {
  font-size: 1.05rem;
  line-height: 200%;
}

.footer__content .footer__sitemap,
.footer__content .footer__logo_description {
  flex: 1;
}

.footer__content .footer__get_in_touch {
  flex: 1.2;
}

.footer__content .footer__get_in_touch {
  line-height: 200%;
  font-size: 1.05rem;
}

.footer__content .footer__get_in_touch h3 {
  line-height: 200%;
  font-size: 1.25rem;
  padding: 1rem 0;
}

.footer__content .footer__get_in_touch p:nth-child(2) {
  padding-bottom: 1rem;
}

.phone_section {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding: 0.4rem 0;
  font-size: 1.05rem;
}

.footer__social-media .facebook-icon,
.footer__social-media .instagram-icon,
.footer__social-media .twitter-icon {
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
}

.footer__social-media .facebook-icon {
  fill: blue;
}

.footer__social-media .instagram-icon {
  fill: red;
}

.footer__social-media {
  padding-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer__social-media .twitter-icon {
  fill: deepskyblue;
}

.footer__sitemap div {
  display: flex;
  flex-direction: column;
}

.footer__sitemap h3 {
  line-height: 200%;
  font-size: 1.25rem;
  padding: 1rem 0;
}

.footer__sitemap a {
  line-height: 200%;
  font-size: 1.05rem;
  width: fit-content;
}

.footer__sitemap a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.copyright {
  padding: 1rem 0;
  text-align: center;
  font-size: 1.1rem;
  font-family: var(--font-family);
  color: var(--color-text);
}

@media screen and (max-width: 1000px) {
  .Footer {
    padding: 0.2rem 2rem 0;
  }

  .footer__content {
    gap: 1.9rem;
  }

  .footer__content .footer__logo_description img {
    max-width: 12rem;
  }

  .footer__content .footer__logo_description p {
    font-size: 1rem;
    line-height: 175%;
  }

  .footer__content .footer__get_in_touch h3 {
    line-height: 175%;
    font-size: 1.1rem;
    padding: 1rem 0;
  }

  .footer__content .footer__get_in_touch p:nth-child(2) {
    line-height: 175%;
    font-size: 0.95rem;
    padding-bottom: 0.3rem;
  }

  .footer__content .footer__get_in_touch .phone_section {
    gap: 0.7rem;
    padding: 0.2rem 0;
    font-size: 0.95rem;
  }
  .footer__content .footer__social-media {
    padding-top: 0.7rem;
  }

  .footer__social-media .facebook-icon,
  .footer__social-media .instagram-icon,
  .footer__social-media .twitter-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .footer__content .footer__sitemap h3 {
    line-height: 175%;
    font-size: 1.1rem;
    padding: 1rem 0;
  }

  .footer__content .footer__sitemap a {
    line-height: 175%;
    font-size: 0.95rem;
  }

  .copyright {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 600px) {
  .Footer {
    width: 100%;
    max-height: 600px;
  }

  .footer__content {
    flex-direction: column;
    gap: 0rem;
  }

  .Footer {
    padding: 0rem 1rem;
  }

  .footer__content .footer__logo_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer__content .footer__logo_description img {
    max-width: 10rem;
    padding: 0;
  }

  .footer__content .footer__logo_description p {
    font-size: 0.8rem;
    line-height: 175%;
  }

  .footer__content .footer__get_in_touch h3 {
    line-height: 175%;
    font-size: 0.95rem;
    padding: 0.85rem 0;
  }

  .footer__content .footer__get_in_touch p:nth-child(2) {
    line-height: 175%;
    font-size: 0.8rem;
    padding-bottom: 0rem;
  }

  .footer__content .footer__get_in_touch .phone_section {
    gap: 1rem;
    padding: 0.2rem 0;
    font-size: 0.8rem;
  }
  .footer__content .footer__social-media {
    padding-top: 0.5rem;
  }

  .footer__social-media .facebook-icon,
  .footer__social-media .instagram-icon,
  .footer__social-media .twitter-icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  .footer__content .footer__sitemap h3 {
    line-height: 175%;
    font-size: 0.95rem;
    padding: 0.85rem 0;
  }

  .footer__sitemap div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .footer__content .footer__sitemap div > a {
    line-height: 175%;
    font-size: 0.8rem;
    padding-bottom: 0rem;
  }

  .copyright {
    font-size: 0.85rem;
  }
}
